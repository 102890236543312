html {
    height: 100%;

}

body {
    min-height: 100%;
    margin: 0;
}


svg.hover,
svg:focus {
    outline: none;
    cursor: pointer;
    filter: drop-shadow(0px 7px 8px rgb(0 0 0 / 20%)) drop-shadow(0px 12px 17px rgb(0 0 0 / 14%)) drop-shadow(0px 5px 22px rgb(0 0 0 / 12%))
}

svg.hover > circle {
    fill: #F39C20;
}

.powerBiEmbeded {
    flex-shrink: 0;
    width: 90vw;
    height: 85vh;
}

.readMore {
    cursor: pointer;
    color: purple;
}